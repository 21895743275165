import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSelectModule } from '@angular/material/select'
import { Router } from '@angular/router'
import { ButtonComponent } from 'src/app/components/atoms'
import { AuthService } from 'src/app/services/auth.service'
import { JeDeclareApiService } from 'src/app/services/je-declare-api.service'

@Component({
  selector: 'app-report-find',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
  ],
  templateUrl: './report-find.component.html',
  styleUrl: './report-find.component.scss',
})
export class ReportFindComponent {
  id: string = ''
  password: string = ''

  errorMessage: string | undefined = undefined
  isLoading = false

  constructor(
    private router: Router,
    private apiService: JeDeclareApiService,
    private authService: AuthService,
  ) {
    if (authService.isAuthenticated()) this.router.navigate(['/admin'])
  }

  onSubmitClick = () => {
    this.isLoading = true
    this.errorMessage = undefined
    this.apiService.getReportById(this.id, this.password).subscribe({
      next: (response) => {
        this.isLoading = false
        this.router.navigate(['/suivi/signalement', this.id], {
          state: {
            reportPassword: this.password,
          },
        })
      },
      error: (error) => {
        this.isLoading = false
        if (error?.error) {
          this.errorMessage = error.error.message
        }
      },
    })
  }
}
