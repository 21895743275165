import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { ReportStepperComponent } from './../../components/organisms/signalement-stepper/signalement-stepper.component'

@Component({
  standalone: true,
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrl: './report-form.component.css',
  imports: [ReportStepperComponent],
})
export class ReportFormComponent {
  steps: {
    number: number
    title: string
    link: string
    active: () => boolean
    clickable: () => boolean
  }[] = [
    {
      number: 1,
      title: 'Initialiser',
      link: '/signalement/initialiser',
      active: () => this.router.url.includes('initialiser'),
      clickable: () => {
        const index = this.steps.findIndex((step) => step.active())
        return index != -1 && index > 0
      },
    },
    {
      number: 2,
      title: 'Identifier',
      link: '/signalement/identifier',
      active: () => this.router.url.includes('identifier'),
      clickable: () => {
        const index = this.steps.findIndex((step) => step.active())
        return index != -1 && index > 1
      },
    },
    {
      number: 3,
      title: 'Décrire',
      link: '/signalement/decrire',
      active: () => this.router.url.includes('decrire'),
      clickable: () => {
        const index = this.steps.findIndex((step) => step.active())
        return index != -1 && index > 2
      },
    },
    {
      number: 4,
      title: 'Transmettre',
      link: '/signalement/transmettre',
      active: () => this.router.url.includes('transmettre'),
      clickable: () => {
        const index = this.steps.findIndex((step) => step.active())
        return index != -1 && index > 3
      },
    },
  ]

  constructor(private router: Router) {}
}
