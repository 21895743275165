import { Component, Input } from '@angular/core'
import { MatExpansionModule } from '@angular/material/expansion'
import { IQuestion } from 'src/app/types/question.type'

@Component({
  selector: 'app-question',
  standalone: true,
  imports: [MatExpansionModule],
  templateUrl: './question.component.html',
})
export class QuestionComponent {
  @Input() item!: IQuestion
  panelOpenState = false
}


