import { Component } from '@angular/core'
import { NavbarComponent } from '../../organisms/navbar/navbar.component'

@Component({
  selector: 'app-layout',
  imports: [NavbarComponent],
  standalone: true,
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  constructor() {}
}
