import { CommonModule, DatePipe } from '@angular/common'
import { AfterContentInit, Component, OnDestroy } from '@angular/core'
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { MatCardModule } from '@angular/material/card'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { Router, RouterLink } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { ButtonComponent } from 'src/app/components/atoms'
import { JeDeclareApiService } from 'src/app/services/je-declare-api.service'
import { IReport } from 'src/app/types/report.type'

@Component({
  selector: 'app-report-confirmation',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    DatePipe,
    FormsModule,
    MatCardModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    RouterLink,
  ],
  templateUrl: './report-confirmation.component.html',
  styleUrl: './report-confirmation.component.scss',
})
export class ReportConfirmationComponent
  implements AfterContentInit, OnDestroy
{
  report: IReport
  uploadErrors: { name: string; error: string }[] = []

  isLoading = false
  requestSucceed = false

  errorMessage: string | undefined

  emailFormControl = new FormControl(
    '',
    Validators.pattern(/^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/),
  )

  constructor(
    private router: Router,
    private apiService: JeDeclareApiService,
    private toastr: ToastrService,
  ) {
    const state = this.router.getCurrentNavigation()?.extras.state
    if (!state || !state['report']) {
      router.navigate(['/'])
    }

    this.report = state?.['report']
    this.uploadErrors = state?.['errors'] ?? []
  }

  ngAfterContentInit(): void {
    this.uploadErrors.forEach((error) =>
      this.toastr.error(error.error, error.name, { disableTimeOut: true }),
    )
  }

  ngOnDestroy(): void {
    this.toastr.clear()
  }

  isValid = () =>
    this.emailFormControl.valid && this.emailFormControl.value !== ''

  handleClick() {
    this.errorMessage = undefined
    this.isLoading = true
    this.requestSucceed = false

    this.apiService
      .sendReportCredentialsByEmail(
        this.emailFormControl.value!,
        this.report.id,
        this.report.password,
      )
      .subscribe({
        next: () => {
          this.requestSucceed = true
        },
        error: (error) => {
          this.requestSucceed = false
          this.errorMessage = error?.error?.message ?? error?.message
        },
      })
      .add(() => {
        this.isLoading = false
        this.emailFormControl.setValue('')
      })
  }
}
