export const texts = {
  welcomeTitle: 'Bienvenue sur "Je déclare"',
  welcomeContent: `Exposez en toute confidentialité vos problèmes internes afin de favoriser un environnement de travail sain et transparent.`,
  importantContent: `Bienvenue sur notre plateforme d'alerte sécurisée, conçue pour permettre la remontée confidentielle d'incidents graves.
          Cette plateforme est indépendante de notre site web et intranet.
          <br />
          <br />
          <strong>Qui peut utiliser cette plateforme ?</strong>
          <ul>
          <li>Employés (CDI, CDD, alternants, stagiaires, etc.)</li>
          <li>Collaborateurs externes occasionnels</li>
          <li>Fournisseurs, prestataires et clients</li>
          </ul>
          
          <strong>Utilisation recommandée :</strong>
          <ul>
          <li>Utilisez un appareil personnel pour accéder à la plateforme.</li>
          <li>Évitez de sauvegarder votre code confidentiel dans votre navigateur.</li>
          </ul>
          <br/>
          Pour des informations détaillées sur les types d'incidents que vous pouvez nous signaler et la protection de vos droits, veuillez consulter la section "À savoir".`,
  orgProfileTitle: "Profil de l'organisation",
  orgDetails: {
    organizationNames: '42c, 42DLP, 42c SI, ÉSTIAM',
    referents: ['Miriam OURAGHI'],
    documents: [
      {
        name: 'Politique de confidentialité 2023',
        link: '#',
      },
    ],
  },
  termsConditions: `<p>
            En utilisant notre service, vous acceptez les termes et conditions suivants :
        </p>
        <h2>1. Acceptation des termes</h2>
        <p>
            Les termes
        </p>`,
  anonymousPopup: `<p>
                <strong>IMPORTANT :</strong> Texte à insérer.
            </p>`,
}
