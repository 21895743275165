<div class="layout">
  <header>
    <app-navbar/>
  </header>
  <main>
    <ng-content></ng-content>
  </main>
  <footer>
    <!-- TODO - Add footer -->
  </footer>
</div>
