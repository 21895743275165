import { Component, Input } from '@angular/core'
import { MatSelectModule } from '@angular/material/select'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'

@Component({
  selector: 'form-field',
  templateUrl: './formfield.component.html',
  styleUrl: './formfield.component.scss',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatSelectModule],
})
export class FormFieldComponent {
  @Input() label?: string
}
