import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

@Component({
  selector: 'app-page-spinner',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './page-spinner.component.html',
  styleUrl: './page-spinner.component.scss',
})
export class PageSpinnerComponent {}
