import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { ActivatedRoute, Router } from '@angular/router'
import { JeDeclareApiService } from 'src/app/services/je-declare-api.service'

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent {
  passwordFC = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ])

  confirmPasswordFC = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ])

  resetPasswordForm = this._formBuilder.group(
    {
      newPassword: this.passwordFC,
      confirmPassword: this.confirmPasswordFC,
    },
    {
      validator: this.ConfirmedValidator('newPassword', 'confirmPassword'),
    },
  )

  email?: string
  session?: string
  errorMessage: string | undefined = undefined

  passwordDisplayed = false
  confirmPasswordDisplayed = false
  isLoading = false
  passwordRequestSucceeded = false

  constructor(
    private apiService: JeDeclareApiService,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    const state = this.router.getCurrentNavigation()?.extras.state
    this.email = state?.['email']

    this.route.queryParams.subscribe((params) => {
      this.session = params['session']
    })
  }

  onSubmitClick = () => {
    this.isLoading = true
    this.errorMessage = undefined
    this.resetPasswordForm.disable()

    const newPassword = this.passwordFC.value

    if (this.session && this.email && newPassword) {
      this.apiService
        .resetPassword(this.email, newPassword, { session: this.session })
        .subscribe({
          next: () => {
            this.passwordRequestSucceeded = true
          },
          error: (error) => {
            console.error(error)
            this.errorMessage = error?.error?.message ?? error?.message
          },
        })
        .add(() => {
          this.isLoading = false
          this.resetPasswordForm.enable()
        })
    }
  }

  handleRedirect = () => this.router.navigate(['/admin/login'])

  passwordIsValid = () => this.passwordFC.valid
  confirmPasswordIsValid = () => this.confirmPasswordFC.valid
  formIsValid = () => this.resetPasswordForm.valid

  togglePasswordVisibility = (
    target: HTMLInputElement,
    el: 'password' | 'confirmPassword',
  ) => {
    target.type === 'password'
      ? (target.type = 'text')
      : (target.type = 'password')
    el === 'password'
      ? (this.passwordDisplayed = !this.passwordDisplayed)
      : (this.confirmPasswordDisplayed = !this.confirmPasswordDisplayed)
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName]
      const matchingControl = formGroup.controls[matchingControlName]
      if (
        matchingControl.errors &&
        !matchingControl.errors['confirmedValidator']
      ) {
        return
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true })
      } else {
        matchingControl.setErrors(null)
      }
    }
  }
}
