<div class="mat-elevation-z1">
  <mat-form-field class="search-field">
    <mat-label>Recherche</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Rechercher" #input/>
  </mat-form-field>

  <table
    mat-table
    [dataSource]="dataSource"
    id="reports-table"
    matSort
    >
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Titre</th>
      <td mat-cell *matCellDef="let element">{{ element.title }}</td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdAt | date : 'dd/MM/yyyy' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>État</th>
      <td mat-cell *matCellDef="let element">
        {{ ReportStatusMapping[element.status] }}
      </td>
    </ng-container>

    <ng-container matColumnDef="companies">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Entités</th>
      <td mat-cell *matCellDef="let element">
        {{ displayCompanies[element.id] }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="reports-table__row"
      (click)="onReportClick(row)"
    ></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">{{ input.value.trim() === '' ? 'Aucun signalement trouvé.' : 'Aucun résultat pour le filtre "' + input.value + '".' }}</td>
    </tr>
  </table>

  <mat-paginator
    [length]="reportsCount"
    [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Sélectionner la page"
  ></mat-paginator>
</div>
