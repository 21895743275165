<app-page-spinner *ngIf="!report"></app-page-spinner>

<app-report-details [report]="report" [isDetailsPage]="true" *ngIf="report"></app-report-details>

<app-report-chat [reportId]="report ? report.id : ''" [reportPassword]="reportPassword ?? ''" *ngIf="report"></app-report-chat>

<!-- <div *ngIf="report">
  <mat-select [(ngModel)]="report.status" [disabled]="!accessToken" (ngModelChange)="onStatusChange()">
    <mat-option *ngFor="let status of statusList" [value]="status.id">{{status.name}}</mat-option>
  </mat-select>
</div> -->

