<mat-stepper orientation="vertical" linear #stepper>
  <mat-step [stepControl]="categoryGroup" [editable]="!isLoading">
    <ng-template matStepLabel>Initialiser</ng-template>
    <form [formGroup]="categoryGroup">
      <mat-form-field>
        <mat-label>Catégorie</mat-label>
        <mat-select formControlName="category">
          <mat-option
            *ngFor="let category of categories"
            [value]="category.id"
            >{{ category.text }}</mat-option
          >
        </mat-select>
        <mat-hint>Le champ est obligatoire</mat-hint>
        <mat-error *ngIf="categoryGroup.get('category')?.hasError('required')"
          >Le champ est obligatoire</mat-error
        >
      </mat-form-field>
    </form>

    <div class="actions-container">
      <button
        mat-raised-button
        matStepperNext
        color="primary"
        [disabled]="!categoryGroup.valid"
      >
        Suivant
      </button>
    </div>
  </mat-step>

  <mat-step [stepControl]="identifyGroup" [editable]="!isLoading">
    <ng-template matStepLabel>Informations personnelles</ng-template>

    <p>
      <mat-slide-toggle
        (change)="onAnonymousChanged($event)"
        [(ngModel)]="isAnonymous"
        >Déclaration anonyme</mat-slide-toggle
      >
    </p>
    <div *ngIf="isAnonymous" class="alert-info">
      <mat-icon>info</mat-icon>
      <div [innerHTML]="texts.anonymousPopup"></div>
    </div>
    <form [formGroup]="identifyGroup">
      <div class="form-group__double">
        <mat-form-field>
          <mat-label>Prénom</mat-label>
          <input matInput formControlName="firstName" type="text" />
          <mat-hint>Le champ est obligatoire</mat-hint>
          <mat-error *ngIf="identifyGroup.get('firstName')?.hasError('required')">
            Le champ est obligatoire
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Nom</mat-label>
          <input matInput formControlName="lastName" />
          <mat-hint>Le champ est obligatoire</mat-hint>
          <mat-error *ngIf="identifyGroup.get('lastName')?.hasError('required')">
            Le champ est obligatoire
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group__double">
        <mat-form-field>
          <mat-label>E-mail</mat-label>
          <input
            matInput
            formControlName="email"
            type="email"
            placeholder="Ex: adresse@mail.com"
          />
          <mat-error *ngIf="identifyGroup.get('email')?.hasError('pattern')"
            >Veuillez vérifier le format de l'adresse mail</mat-error
          >
        </mat-form-field>

        <mat-form-field>
          <mat-label>Téléphone</mat-label>
          <input
            matInput
            formControlName="phoneNumber"
            type="tel"
            placeholder="Ex: 0123456789, +33123456789, 01 23 45 67 89, ..."
          />
          <mat-error *ngIf="identifyGroup.get('phoneNumber')?.hasError('pattern')">
            Veuillez vérifier le format du numéro de téléphone
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group__double">
        <mat-form-field>
          <mat-label>Entreprise</mat-label>
          <input matInput formControlName="company" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Poste</mat-label>
          <input matInput formControlName="job" />
        </mat-form-field>
      </div>
    </form>

    <div class="actions-container">
      <button mat-raised-button matStepperPrevious color="primary">
        Retour
      </button>
      <button
        mat-raised-button
        matStepperNext
        color="primary"
        [disabled]="!identifyGroup.valid && !isAnonymous"
      >
        Suivant
      </button>
    </div>
  </mat-step>

  <mat-step [stepControl]="describeGroup" [editable]="!isLoading">
    <ng-template matStepLabel>Décrire</ng-template>
    <form [formGroup]="describeGroup">
      <mat-form-field>
        <mat-label>Titre</mat-label>
        <input matInput formControlName="title" />
        <mat-hint>Le champ est obligatoire</mat-hint>
        <mat-error *ngIf="describeGroup.get('title')?.hasError('required')">
          Le champ est obligatoire
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Quelle est la société concernée par l’infraction ?</mat-label>
        <mat-select
          placeholder="Sélectionner des sociétés"
          formControlName="companies"
          multiple
          [(value)]="selectedCompanies"
          (selectionChange)="onCompanySelect($event)"
        >
          <mat-option *ngFor="let company of companies" [value]="company">
            {{ company.name }}
          </mat-option>
          <mat-option [value]="{ id: 'other', name: 'Autre(s)' }">
            Autre(s)
          </mat-option>
        </mat-select>

        <mat-hint>Le champ est obligatoire</mat-hint>
        <mat-error *ngIf="describeGroup.get('companies')?.hasError('required')">
          Le champ est obligatoire
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="showOtherField">
        <mat-label>Autres entreprises concernées</mat-label>
        <mat-chip-grid #chipGrid formControlName="otherCompanies">
          @for (otherCompany of otherCompanies; track otherCompany) {
            <mat-chip-row (removed)="removeOtherCompany(otherCompany)" [editable]="true" (edited)="editOtherCompany(otherCompany, $event)">
              {{ otherCompany.name }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          }

          <input
            placeholder="Nouvelle entreprise..."
            [matChipInputFor]="chipGrid"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addOtherCompany($event)"
          />
        </mat-chip-grid>
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          Comment avez-vous eu connaissance des faits relatifs à l’infraction ?
        </mat-label>
        <mat-select formControlName="how">
          <mat-option *ngFor="let how of reportHow" [value]="how.id">
            {{ how.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Détails</mat-label>
        <textarea
          matInput
          rows="4"
          formControlName="details"
          placeholder="Veuillez fournir tous les détails concernant les faits que vous souhaitez signaler et toutes les informations qui pourraient être utiles pour l’enquête et la résolution de cette situation"
        ></textarea>
        <mat-hint>Le champ est obligatoire</mat-hint>
        <mat-error *ngIf="describeGroup.get('details')?.hasError('required')">
          Le champ est obligatoire
        </mat-error>
      </mat-form-field>

      <div class="form-group__double">
        <mat-form-field>
          <mat-label>Date des faits</mat-label>
          <textarea
            matInput
            rows="2"
            formControlName="when"
            placeholder="Quand les faits se sont-ils produits ? Sont-ils toujours en cours ?"
          ></textarea>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Lieu des faits</mat-label>
          <textarea
            matInput
            rows="2"
            formControlName="where"
            placeholder="Où les faits se sont-ils produits ?"
          ></textarea>
        </mat-form-field>
      </div>

      <mat-form-field>
        <mat-label>Personnes impliquées</mat-label>
        <textarea
          matInput
          rows="2"
          formControlName="involvedPersons"
          placeholder="Veuillez indiquer la ou les personnes impliquée(s)"
        ></textarea>
      </mat-form-field>

      <label id="hierarchyAwareness-label"
        >Votre hiérarchie a-t-elle connaissance de ces faits ?</label
      >
      <mat-radio-group
        aria-labelledby="hierarchyAwareness-label"
        class="flex-column"
        formControlName="hierarchyAwareness"
      >
        @for (hierarchyAwareness of reportHierarchyAwareness; track
        hierarchyAwareness) {
        <mat-radio-button [value]="hierarchyAwareness.id">
          {{ hierarchyAwareness?.text }}
        </mat-radio-button>
        }
      </mat-radio-group>
    </form>
    <div class="actions-container">
      <button mat-raised-button matStepperPrevious color="primary">
        Retour
      </button>
      <button
        mat-raised-button
        matStepperNext
        color="primary"
        [disabled]="!describeGroup.valid"
      >
        Suivant
      </button>
    </div>
  </mat-step>

  <mat-step [stepControl]="resumeGroup" [editable]="!isLoading">
    <ng-template matStepLabel>Confirmer</ng-template>
    <form [formGroup]="resumeGroup">
      <app-report-details
        [report]="newReport"
        [selectedCompanies]="selectedCompanies"
        [otherCompanies]="otherCompanies"
      ></app-report-details>

      <app-files-selector
        (filesChange)="onFilesChanged($event)"
        [isLoading]="isLoading"
      ></app-files-selector>

      <hr />

      <mat-checkbox formControlName="acceptConditions">
        J'accepte les
        <a
          href="javascript:void(0)"
          (click)="openTermsAndConditions()"
          class="terms-conditions-link"
        >
          termes et conditions
        </a>
      </mat-checkbox>
      <mat-error
        *ngIf="resumeGroup.get('acceptConditions')?.hasError('required')"
        >Le champ est obligatoire</mat-error
      >
    </form>

    <div class="actions-container" *ngIf="!isLoading">
      <button mat-raised-button matStepperPrevious color="primary">
        Retour
      </button>
      <button
        mat-raised-button
        matStepperNext
        color="primary"
        [disabled]="!allFormsAreValid() || isLoading"
        (click)="finalSubmit()"
      >
        Transmettre le signalement
      </button>
    </div>

    <div id="spinner-container" *ngIf="isLoading">
      <mat-spinner></mat-spinner>
    </div>

    <p *ngIf="errorMessage" class="error-message text-center">
      {{ errorMessage }}
    </p>
  </mat-step>
</mat-stepper>
