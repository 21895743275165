import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes'
import { AsyncPipe, CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import {
  MatChipEditedEvent,
  MatChipInputEvent,
  MatChipsModule,
} from '@angular/material/chips'
import { MatOptionModule } from '@angular/material/core'
import { MatDialog } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectChange, MatSelectModule } from '@angular/material/select'
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle'
import { MatStepperModule } from '@angular/material/stepper'
import { Router } from '@angular/router'
import { map, startWith } from 'rxjs'
import { ReportDetailsComponent } from 'src/app/components/organisms/report-details/report-details.component'
import {
  reportCategories,
  reportHierarchyAwareness,
  reportHow,
} from 'src/app/data/report.data'
import { texts } from 'src/app/data/texts.data'
import { JeDeclareApiService } from 'src/app/services/je-declare-api.service'
import { UploadedFile } from 'src/app/types/files.type'
import { IReportFormCompany } from 'src/app/types/report-form.type'
import { FilesSelectorComponent } from '../../molecules/files-selector/files-selector.component'
import { TermsConditionsComponent } from '../../molecules/terms-conditions/terms-conditions.component'
@Component({
  selector: 'app-report-stepper',
  templateUrl: './signalement-stepper.component.html',
  styleUrls: ['./signalement-stepper.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    CommonModule,
    FormsModule,
    FilesSelectorComponent,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatStepperModule,
    ReactiveFormsModule,
    ReportDetailsComponent,
  ],
})
export class ReportStepperComponent {
  texts = texts
  isLinear = true
  isLoading = false
  isUploading = false
  isAnonymous = false
  showOtherField = false

  errorMessage: string | undefined

  categoryGroup!: FormGroup
  identifyGroup!: FormGroup
  describeGroup!: FormGroup
  resumeGroup!: FormGroup

  categories = reportCategories
  reportHow = reportHow
  reportHierarchyAwareness = reportHierarchyAwareness

  companyValue = ''
  companies: IReportFormCompany[] = []
  selectedCompanies: IReportFormCompany[] = []

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, TAB] as const
  otherCompanies: IReportFormCompany[] = []

  newReport: Record<string, any> = {}

  files: UploadedFile[] = []

  constructor(
    private _formBuilder: FormBuilder,
    private apiService: JeDeclareApiService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.apiService.getCompanies().subscribe((response) => {
      this.companies =
        response.data?.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
        ) ?? []
    })

    this.categoryGroup = this._formBuilder.group({
      category: ['', Validators.required],
    })

    this.identifyGroup = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: [
        '',
        Validators.pattern(
          /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/,
        ),
      ],
      email: ['', Validators.pattern(/^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/)],
      company: [''],
      job: [''],
    })

    this.describeGroup = this._formBuilder.group({
      title: ['', Validators.required],
      companies: ['', Validators.required],
      otherCompanies: [this.otherCompanies, Validators.required],
      how: [''],
      details: ['', Validators.required],
      when: [''],
      where: [''],
      involvedPersons: [''],
      hierarchyAwareness: [''],
    })

    this.resumeGroup = this._formBuilder.group({
      acceptConditions: [false, Validators.requiredTrue],
    })

    this.categoryGroup.valueChanges.subscribe(
      (data) =>
        (this.newReport = {
          ...this.newReport,
          ...data,
        }),
    )

    this.identifyGroup.valueChanges.subscribe(
      (data) =>
        (this.newReport = {
          ...this.newReport,
          user: this.isAnonymous ? null : { ...data },
        }),
    )

    this.describeGroup.valueChanges
      .pipe(
        startWith({ companies: '' }),
        map((values) => {
          const { companies } = values
          if (this.companyValue !== companies) this.companyValue = companies

          return {
            ...values,
            companies: this.selectedCompanies.map((c) => c.id),
          }
        }),
      )
      .subscribe((data) => (this.newReport = { ...this.newReport, ...data }))
  }

  openTermsAndConditions(): void {
    this.dialog.open(TermsConditionsComponent)
  }

  onAnonymousChanged = (event: MatSlideToggleChange) => {
    if (event.checked) {
      this.newReport['user'] = null
      this.identifyGroup.disable()
    } else {
      this.newReport['user'] = this.identifyGroup.value
      this.identifyGroup.enable()
    }
  }

  onFilesChanged = (files: UploadedFile[]) => (this.files = files)

  allFormsAreValid = () =>
    this.resumeGroup.valid &&
    this.resumeGroup.controls['acceptConditions'].value &&
    this.categoryGroup.valid &&
    (this.identifyGroup.valid || this.isAnonymous) &&
    this.describeGroup.valid

  finalSubmit() {
    this.errorMessage = undefined
    this.isLoading = true

    // Disable all form group
    this.categoryGroup.disable()
    this.identifyGroup.disable()
    this.describeGroup.disable()
    this.resumeGroup.disable()

    // Create our final report
    const finalReport = {
      ...this.newReport,
      companies: this.selectedCompanies.filter((c) => c.id !== 'other'),
      otherCompanies: this.otherCompanies,
      // add our files data to generate presigned URLs
      ...(this.files.length && {
        files: this.files.map((f) => ({
          name: f.file.name,
          size: f.file.size,
          originalName: f.originalName,
        })),
      }),
    }

    this.apiService.createReport(finalReport).subscribe({
      next: async (response) => {
        const { presignedUrls, errors = [] } = response?.data?.uploads ?? {}
        const keys = Object.keys(presignedUrls ?? {})
        const reportPassword = response.data?.report?.password ?? ''

        // If we have presigned URLs, we can send our files
        if (presignedUrls && keys.length) {
          this.isUploading = true

          await Promise.all(
            keys.map(async (fileName) => {
              const url = presignedUrls[fileName]
              const file = this.files.find((f) => f.file.name === fileName)

              if (file) {
                try {
                  await fetch(url, {
                    method: 'PUT',
                    body: file.file,
                    headers: { 'Content-Type': file.file.type },
                  })
                } catch (e: any) {
                  // If error, push it to the errors list that will be displayed
                  errors.push({
                    name: fileName,
                    error: `Le fichier n'a pas pu être envoyé. ${e?.message}`,
                  })
                }
              }
            }),
          )
        }

        // If we got errors on presigned URLs creation
        // we have to remove files from report database object
        if (errors.length) {
          this.apiService
            .deleteFailedFiles(reportPassword, {
              reportId: response.data?.report?.id ?? '',
              files: errors?.map((e) => e.name),
            })
            .subscribe()
            .add(() => {
              this.router.navigate(['/signalement/confirmation'], {
                state: { errors, report: response.data?.report },
              })
            })
        } else {
          this.router.navigate(['/signalement/confirmation'], {
            state: { report: response.data?.report },
          })
        }
      },
      error: (error) => {
        console.warn(error)
        this.errorMessage = error?.error?.message ?? error?.message
        this.isLoading = false

        this.categoryGroup.enable()
        this.identifyGroup.enable()
        this.describeGroup.enable()
        this.resumeGroup.enable()
      },
    })
  }

  onCompanySelect(event: MatSelectChange): void {
    const { value } = event
    this.showOtherField = !!value.find(
      (c: IReportFormCompany) => c.id === 'other',
    )
    this.showOtherField
      ? this._addOtherCompaniesValidator()
      : this._removeOtherCompaniesValidator()
    this.selectedCompanies = event.value
  }

  addOtherCompany(event: MatChipInputEvent) {
    const value = (event.value || '').trim()
    if (value) {
      this.otherCompanies = [...this.otherCompanies, { id: null, name: value }]
    }
    event.chipInput!.clear()
  }

  removeOtherCompany(otherCompany: IReportFormCompany): void {
    const index = this.otherCompanies.findIndex(
      (o) => o.name === otherCompany.name,
    )
    if (index >= 0) this.otherCompanies.splice(index, 1)
    if (this.otherCompanies.length === 0) this._clearFormOtherCompanies()
  }

  editOtherCompany(
    otherCompany: IReportFormCompany,
    event: MatChipEditedEvent,
  ) {
    const value = event.value.trim()

    if (!value) {
      this.removeOtherCompany(otherCompany)
      return
    }

    const index = this.otherCompanies.findIndex(
      (o) => o.name === otherCompany.name,
    )
    if (index >= 0) {
      this.otherCompanies[index].name = value
    }
    if (this.otherCompanies.length === 0) this._clearFormOtherCompanies()
  }

  private _clearFormOtherCompanies = () => {
    const otherCompaniesField = this.describeGroup.get('otherCompanies')!
    otherCompaniesField.setValue([])
    otherCompaniesField.updateValueAndValidity()
  }

  private _removeOtherCompaniesValidator = () => {
    const otherCompaniesField = this.describeGroup.get('otherCompanies')!
    otherCompaniesField.removeValidators(Validators.required)
    otherCompaniesField.updateValueAndValidity()
  }

  private _addOtherCompaniesValidator = () => {
    const otherCompaniesField = this.describeGroup.get('otherCompanies')!
    otherCompaniesField.addValidators(Validators.required)
    otherCompaniesField.updateValueAndValidity()
  }
}
