import { IReportFormCategory, IReportFormHierarchyAwareness, IReportFormHow } from "../types/report-form.type";

export const reportCategories: IReportFormCategory[] = [
  {
    id: "2061",
    text: "00. Test",
    description: "",
  },
  {
    id: "2062",
    text: "01. Corruption, paiement de facilitation",
    description: "Qu’est-ce que la corruption ? C’est le fait d’employer des moyens condamnables pour influencer une personne dans ses décisions ou la faire contrevenir à ses obligations. Cela implique un avantage indû (cadeau, invitation, argent, rabais,….) en lien avec des partenaires commerciaux, fournisseurs, prestataires, clients, autorités ou autres tiers éventuels.\n\nQu’est-ce qu’un paiement de facilitation ? c’est une contribution de faible montant (argent, cadeau,…) destinée à un agent de la fonction publique afin qu’il garantisse ou accélère une procédure administrative courante ou des formalités, dont il est responsable. 42 Consulting doit suivre les procédures légales normales et sans compensation.",
  },
  {
    id: "2063",
    text: "02. Protection des données / Sécurité informatique",
    description: "Utilisation/transfert non autorisé ou illicite d'informations confidentielles ou propriétaires ou de données personnelles. Manipulation non autorisée ou illicite des réseaux informatiques ou des systèmes d'exploitation.",
  },
  {
    id: "2064",
    text: "03. Conflit d'intérêts",
    description: "Un conflit d’intérêts est une situation dans laquelle les intérêts personnels d’un collaborateur, ou ceux de ses proches, sont (ou risquent d’être) en conflit avec ceux du groupe.\nExemples : un lien familial entre un collaborateur et un employé d’un client ou d’un fournisseur qui peut influencer les décisions ; l‘acceptation ou l‘offre d’un cadeau ou d’une invitation ne respectant pas les règles édictées par l’entreprise ; une activité ou une mission extérieure pouvant influencer les décisions d’un collaborateur,…",
  },
  {
    id: "2065",
    text: "04. Abus de biens sociaux",
    description: "Délit qui consiste à utiliser en connaissance de cause les fonds, les biens ou les actifs de l’entreprise à des fins personnelles, directes ou indirectes.",
  },
  {
    id: "2066",
    text: "05. Vol, fraude",
    description: "D’une manière générale, cela concerne l’argent ou toute autre valeur en capital appartenant à l’entreprise, qui n’est pas utilisé dans son intérêt ; l’entreprise subit un dommage (une perte) et l’auteur de l’infraction ou un tiers s’enrichit de manière illégitime. Il peut s’agir de vol d’objet ou d’argent, mais aussi de remboursements de frais qui ne sont pas justifiés sur le plan professionnel.",
  },
  {
    id: "2067",
    text: "06. Pratiques anti-concurrentielles",
    description: "Accords, arrangements ou ententes oraux ou écrits avec d'autres acteurs commerciaux pour fixer les prix, boycotter des fournisseurs ou des clients spécifiques, affecter des produits, des territoires ou des marchés ou échanger des informations sur le plan de la concurrence et toutes discussions concernant les prix, des indemnités ou rabais commerciaux, coûts, concurrence, plans ou études marketing, plans et capacités de production ou toutes autres informations confidentielles.",
  },
  {
    id: "2068",
    text: "07. Discrimination, harcèlement",
    description: "Cette catégorie inclut les discriminations des employés fondées sur le sexe, l’âge, l‘origine, la religion, l‘orientation sexuelle, le handicap, l‘état de santé, l‘apparence physique, l‘appartenance syndicale, …, mais aussi le harcèlement ou tout autre comportement abusif ou intimidation.",
  },
  {
    id: "2069",
    text: "08. Sécurité et santé au travail",
    description: "Cette catégorie inclut toute condition ou activité qui expose à des risques sanitaires ou sécuritaires non maitrisés les salariés, visiteurs, clients, fournisseurs ou d’autres tiers sur les sites du groupe 42 Consulting ou les salariés ou d’autres tiers agissant pour le compte de 42 Consulting en dehors des locaux de 42 Consulting.",
  },
  {
    id: "2070",
    text: "09 .Protection environnementale",
    description: "Cette catégorie concerne en général tout acte qui nuit à l’environnement ou menace la santé publique. Ceci inclut les conditions qui pourraient être dangereuses pour l’environnement ou menacer directement ou indirectement la santé des hommes, des animaux, des végétaux ou de la vie.",
  },
  {
    id: "2071",
    text: "10. Représailles à l'encontre d'un lanceur d'alerte",
    description: "Catégorie à sélectionner si vous avez lancé une alerte dans le passé et que vous estimez subir des représailles du fait d’avoir lancé cette alerte.",
  },
  {
    id: "2072",
    text: "11. Autres",
    description: "Si vous estimez que les faits rapportés ne rentrent pas dans l’une des catégories précitées, veuillez utiliser celle-ci.",
  },
]

export const reportHow: IReportFormHow[] = [
  {
    id: "362",
    text: "Je les ai observés",
  },
  {
    id: "363",
    text: "Je les ai entendus",
  },
  {
    id: "364",
    text: "Cela m'est arrivé",
  },
  {
    id: "365",
    text: "Cela m'a été dit par un collègue",
  },
  {
    id: "366",
    text: "Cela m'a été dit par quelqu'un en dehors de la société",
  },
  {
    id: "367",
    text: "Je l'ai entendu dire",
  },
  {
    id: "368",
    text: "J'ai accidentellement trouvé un document ou un fichier",
  },
  {
    id: "369",
    text: "Autre",
  },
]

export const reportHierarchyAwareness: IReportFormHierarchyAwareness[] = [
  {
    id: "425",
    text: "Je ne sais pas",
  },
  {
    id: "426",
    text: "Oui",
  },
  {
    id: "427",
    text: "Non",
  },
]