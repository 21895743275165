<mat-card id="confirmation-card">
  <mat-card-header id="confirmation-card__header">
    <mat-card-title>Votre signalement a bien été transmis</mat-card-title>
    <mat-card-subtitle>Merci de votre confiance</mat-card-subtitle>
  </mat-card-header>
  
  <mat-divider></mat-divider>
  <mat-card-content id="confirmation-card__content">
    <h3>Voici les identifiants à conserver pour retrouver votre signalement.</h3>

    <table>
      <tr><td><b>Identifiant :</b></td><td>{{ report.id }}</td></tr>
      <tr><td><b>Mot de passe :</b></td><td>{{ report.password }}</td></tr>
    </table>

    <p><strong style="color: red;">Important : </strong>Votre code est personnel, unique et <strong>non réinitialisable</strong>.
    Conservez-le. Il vous permet depuis la page d’accueil d’accéder à votre signalement et d’échanger des messages
    privés et confidentiels avec votre/vos référent(s).
    En cas de <strong>perte ou d’oubli</strong> de ce code, vous ne pourrez plus accéder au suivi de votre signalement.</p>

    <form>
      <mat-form-field style="width: 100%;">
        <mat-label>E-mail</mat-label>
        <input matInput [formControl]="emailFormControl" type="email" #email placeholder="Ex: adresse@mail.com" />
        <mat-hint>Cette étape est facultative et totalement anonyme</mat-hint>
        <mat-error *ngIf="!isValid()">Veuillez vérifier le format de l'adresse mail</mat-error>
      </mat-form-field>

      <app-button [label]="'Recevoir mes identifiants par e-mail'"
                  [type]="'submit'"
                  [disabled]="!isValid() || isLoading"
                  (click)="!isValid() || isLoading ? null : handleClick()"
                  *ngIf="!isLoading"
      ></app-button>

      <div id="spinner-container" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
      </div>
    </form>

    <h4 *ngIf="requestSucceed" style="color: green;">Les identifiants vous ont bien été envoyés par e-mail.</h4>
    <span *ngIf="errorMessage" style="color: red;">{{ errorMessage }}</span>

    <p><strong style="color: red;">Important : </strong>Vous pouvez, de manière <strong>facultative</strong>, renseigner votre adresse e-mail
    ou une adresse e-mail que vous aurez créée pour votre alerte. Cette adresse e-mail ne sera <strong>pas visible par
    le(s) référent(s)</strong> qui auront reçu votre signalement. Si vous renseignez une adresse e-mail, la
    plateforme vous transmettra votre code confidentiel, vous enverra des notifications des messages reçus sur la
    plateforme et lorsque votre signalement sera clôturé. Si vous ne renseignez pas d’adresse e-mail, vous devrez vous
    connecter régulièrement à la plateforme, en utilisant le code confidentiel ci-dessus, afin d’assurer le suivi de
    votre signalement.</p>
  </mat-card-content>
</mat-card>
