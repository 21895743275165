import { Component } from '@angular/core'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { RouterLink } from '@angular/router'
import { BannerComponent } from 'src/app/components/molecules'
import {
  ActionCardComponent,
  QuestionsOrgaComponent,
} from 'src/app/components/organisms'
import { questions } from 'src/app/data/questions.data'
import { texts } from 'src/app/data/texts.data'
import { IQuestion } from 'src/app/types/question.type'
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    RouterLink,
    ActionCardComponent,
    QuestionsOrgaComponent,
    BannerComponent,
    MatIconModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  questions: IQuestion[] = questions
  texts = texts

  downloadDocument(documentName: string) {
    const link = document.createElement('a')
    link.href = `assets/docs/${documentName}`
    link.download = documentName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon(
      'important_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './../../../assets/icons/important-message-1448-svgrepo-com.svg',
      ),
    )
    this.matIconRegistry.addSvgIcon(
      'profil_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './../../../assets/icons/user-svgrepo-com.svg',
      ),
    )
  }
}
