<ng-container *ngIf="logo; else fullButton">
  <a [routerLink]="routerLink ? routerLink : null" (click)="handleClick($event)">
    <img [src]="logo" alt="Logo" />
  </a>
</ng-container>

<ng-template #fullButton>
  <button
    mat-raised-button
    [routerLink]="routerLink ? routerLink : null"
    [class]="class ? class : null"
    [type]="type"
    [color]="color"
    [disabled]="disabled"
    (click)="handleClick($event)"
    *ngIf="isRaised"
  >
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    {{ label }}
  </button>

  <button
    mat-button
    [routerLink]="routerLink ? routerLink : null"
    [class]="class ? class : null"
    [type]="type"
    [color]="color"
    [disabled]="disabled"
    (click)="handleClick($event)"
    *ngIf="!isRaised"
  >
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    {{ label }}
  </button>
</ng-template>
