import { IQuestion } from '../types/question.type'

export const questions: IQuestion[] = [
  {
    question: 'Quels incidents dois-je signaler ?',
    answer:
      "Alertez-nous en cas de comportements ou d'actions au sein de 42 Consulting qui pourraient constituer des infractions pénales, des violations importantes des lois ou des règlements (internationaux inclus), des menaces sérieuses à l'intérêt général, ou des infractions au Code de conduite anti-corruption.",
  },
  {
    question: 'Comment votre identité est-elle protégée ?',
    answer:
      "Lorsque vous signalez un incident de manière honnête en vous identifiant, 42 Consulting assure la confidentialité de votre identité. Votre nom ne sera partagé qu'avec les membres internes nécessaires à l'enquête et ne sera pas divulgué à la personne accusée.",
  },
  {
    question: "Est-il possible de rester anonyme lors d'un signalement ?",
    answer:
      "Bien que l'identification soit préférable pour une enquête approfondie, vous pouvez choisir de rester anonyme lors de votre signalement. Les alertes anonymes ne seront traitées que si les faits rapportés sont jugés suffisamment graves et détaillés pour justifier une enquête, selon les procédures internes établies.",
  },
  {
    question: "Que se passe-t-il après le dépôt d'une alerte ? ",
    answer:
      "Une fois votre alerte déposée, elle est directement reçue par la directrice des ressources humaines de 42 Consulting. Elle examinera les détails de l'alerte et pourra vous contacter pour des précisions ou des informations supplémentaires. Un statut indique l'état de l'alerte (A traiter, Ouvert, Clos, Rejeté). Les enquêtes peuvent prendre du temps, surtout dans des situations complexes, et pour des raisons de confidentialité, les résultats détaillés de l'enquête peuvent ne pas être partagés. L'identité des personnes impliquées reste protégée tout au long du processus.",
  },
  {
    question:
      "Quels sont les délais à respecter pour le traitement d'une alerte ?",
    answer:
      "Une fois votre alerte soumise, vous serez informé du délai nécessaire pour évaluer sa recevabilité, qui ne dépassera pas un mois. En situation d'urgence, le signalement peut être communiqué directement aux autorités compétentes.",
  },
  {
    question: 'À quoi sert la fonction « Accéder à un signalement existant » ?',
    answer:
      "Après avoir signalé un incident, vous recevrez un code confidentiel. Ce code vous permet de vous connecter à votre espace de suivi personnel à tout moment en sélectionnant « Accéder à un signalement existant ». Cette fonctionnalité est essentielle pour communiquer directement avec le référent de 42 Consulting, permettant un échange d'informations qui peut s'avérer crucial pour résoudre l'incident de manière complète.",
  },
  {
    question:
      "Dois-je craindre des conséquences négatives si j'envoie une alerte ?",
    answer:
      "42 Consulting garantit qu'aucune sanction ne sera prise contre vous si vous signalez une alerte de bonne foi et de manière désintéressée. Cela signifie que vous avez des raisons fondées de croire en la véracité de ce que vous rapportez et que les faits constituent une violation de la loi ou une conduite inappropriée. En revanche, une alerte de mauvaise foi peut exposer son auteur à des sanctions.",
  },
  {
    question:
      "Que se passe-t-il si le contenu d'une alerte s'avère erroné par la suite ?",
    answer:
      "L'essentiel est que, lors de la soumission de l'alerte, vous pensiez raisonnablement que les faits rapportés sont exacts et qu'ils constituent une violation de la loi ou un comportement inapproprié. Vous n'avez pas à fournir de preuves ou à résoudre le problème vous-même. Les référents enquêteront selon la procédure établie. Si l'enquête conclut qu'il n'y a pas eu de violation, il n'y aura pas de répercussions négatives pour vous. Toutefois, une alerte de mauvaise foi peut entraîner des sanctions pénales pour dénonciation calomnieuse.",
  },
  {
    question: 'Que deviennent les données une fois le dossier clos ?',
    answer:
      "Les données identifiant l'émetteur de l'alerte et les personnes visées sont détruites après 48h si l'alerte est jugée irrecevable. Si l'alerte est recevable, elles sont détruites trois mois après la clôture de toutes les opérations de vérification.",
  },
]
