import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './guards/auth.guard'
import { ForgotPasswordComponent } from './pages/admin/forgot-password/forgot-password.component'
import { LoginComponent } from './pages/admin/login/login.component'
import { ReportsListComponent } from './pages/admin/reports-list/reports-list.component'
import { ResetPasswordComponent } from './pages/admin/reset-password/reset-password.component'
import { HomeComponent } from './pages/home/home.component'
import { ReportConfirmationComponent } from './pages/report-confirmation/report-confirmation.component'
import { ReportFindComponent } from './pages/report-find/report-find.component'
import { ReportFormComponent } from './pages/report-form/report-form.component'
import { ReportIdComponent } from './pages/report-id/report-id.component'

const routes: Routes = [
  { path: '', pathMatch: 'full', component: HomeComponent },
  {
    path: 'signalement',
    children: [
      { path: '', component: ReportFormComponent },
      { path: 'confirmation', component: ReportConfirmationComponent },
      { path: '**', redirectTo: '' },
    ],
  },
  {
    path: 'suivi/signalement',
    component: ReportFindComponent,
  },
  {
    path: 'suivi/signalement/:id',
    component: ReportIdComponent,
  },
  {
    path: 'admin/login',
    component: LoginComponent,
  },
  {
    path: 'admin/forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'admin/reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ReportsListComponent,
      },
      {
        path: 'signalement/:id',
        component: ReportIdComponent,
      },
      { path: '**', redirectTo: '' },
    ],
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
