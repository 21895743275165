import { HttpInterceptorFn } from '@angular/common/http';
import { SessionStorageUtils } from '../utils/session-storage.utils';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const accessToken = SessionStorageUtils.ACCESS_TOKEN.get()

  let authReq = req
  if (accessToken && !req.headers.get("Authorization")) {
    authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    })
  }

  return next(authReq);
};
