<section id="main-form__section" class="full-width">

  <div id="main-form__container" *ngIf="passwordRequestSucceeded">
    <h1 id="main-form__title">Mot de passe réinitialisé</h1>
    <p>Votre mot de passe a été réinitialisé avec succès ! Vous pouvez à présent vous connecter :</p>
    <button mat-button mat-raised-button color="primary" (click)="handleRedirect()">Page de connexion</button>
  </div>
  
  <form id="main-form__container" *ngIf="!passwordRequestSucceeded">
    <h1 id="main-form__title">Mot de passe oublié</h1>
    <mat-form-field class="full-width">
      <mat-label>E-mail du gestionnaire</mat-label>
      <input name="email" matInput [formControl]="emailFC" type="email" />
      <mat-error *ngIf="!emailIsValid()">L'adresse e-mail est obligatoire</mat-error>
    </mat-form-field>

    <ng-container *ngIf="requestSucceeded">
      <mat-form-field class="full-width">
        <mat-label>Nouveau mot de passe</mat-label>
        <input name="password" #password matInput [formControl]="passwordFC" type="password" />
        <button matSuffix mat-icon-button aria-label="Clear" (click)="togglePasswordVisibility(password, 'password')">
          <mat-icon>{{ passwordDisplayed ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="!passwordIsValid()">8 caractères minimum</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Confirmation du mot de passe</mat-label>
        <input name="confirmPassword" #confirmPassword matInput [formControl]="confirmPasswordFC" type="password" />
        <mat-error *ngIf="!confirmPasswordIsValid()">Les mots de passe ne sont pas similaires</mat-error>
        <button matSuffix mat-icon-button aria-label="Clear" (click)="togglePasswordVisibility(confirmPassword, 'confirmPassword')">
          <mat-icon>{{ confirmPasswordDisplayed ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Code de réinitialisation</mat-label>
        <input name="code" matInput [formControl]="codeFC" />
        <mat-error *ngIf="!codeIsValid()">Veuillez vérifier le code renseigné</mat-error>
        <mat-hint>Le code vous a été envoyé par e-mail</mat-hint>
      </mat-form-field>
    </ng-container>

    <div id="spinner-container" *ngIf="isLoading">
      <mat-spinner></mat-spinner>
    </div>

    <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
    <button mat-button mat-raised-button color="primary" *ngIf="!isLoading && !requestSucceeded" type="submit" (click)="!emailIsValid() || isLoading ? null : onSubmitClick()" [disabled]="!emailIsValid() || isLoading">Recevoir un code de réinitialisation</button>
    <button mat-button mat-raised-button color="primary" *ngIf="!isLoading && requestSucceeded" type="submit" (click)="!resetPasswordFormIsValid() || isLoading ? null : onPasswordSubmitClick()" [disabled]="!resetPasswordFormIsValid() || isLoading">Réinitialiser mon mot de passe</button>
  </form>
</section>
