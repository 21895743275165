<section id="main-form__section" class="full-width">
  <div id="main-form__container">
    <h1 id="main-form__title">Suivre un signalement</h1>
    <mat-form-field class="full-width">
      <mat-label>ID de suivi</mat-label>
      <input matInput [(ngModel)]="id" type="text" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Mot de passe</mat-label>
      <input matInput [(ngModel)]="password" type="password" />
    </mat-form-field>

    <div id="spinner-container" *ngIf="isLoading">
      <mat-spinner></mat-spinner>
    </div>

    <app-button
      *ngIf="!isLoading"
      [label]="'Ouvrir'"
      [type]="'submit'"
      (click)="onSubmitClick()"
      [class]="'full-width'"
    />
    <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
  </div>
</section>
