import { Component } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogRef } from '@angular/material/dialog'
import { texts } from 'src/app/data/texts.data'
@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
  standalone: true,
  imports: [MatButtonModule],
})
export class TermsConditionsComponent {
  constructor(private dialogRef: MatDialogRef<TermsConditionsComponent>) {}
  texts = texts
  close(): void {
    this.dialogRef.close()
  }
}
