<mat-toolbar>
  <app-button
    [logo]="'../../../../assets/images/42c-logo.png'"
    [routerLink]="'/'"
  />
  <span class="link-spacer"></span>
  <app-button
    [isRaised]="false"
    [label]="'Nouveau signalement'"
    [routerLink]="'/signalement/initialiser'"
    />
    <app-button
    [isRaised]="false"
    [label]="'Accéder à un signalement'"
    [routerLink]="'/suivi/signalement'"
    *ngIf="!isLoggedIn()"
  />
  <ng-container *ngIf="!isLoggedIn()"><app-button [isRaised]="false" [label]="'Accès pro'" [routerLink]="'/admin/login'" /></ng-container>
  <ng-container *ngIf="isLoggedIn()"><app-button [isRaised]="false" [label]="'Signalements'" [routerLink]="'/admin'" /></ng-container>
  <ng-container *ngIf="isLoggedIn()"><app-button [isRaised]="false" [label]="'Se déconnecter'" (onClick)="handleLogout()" /></ng-container>
</mat-toolbar>
