import { Component, Input } from '@angular/core'
import { QuestionComponent } from '../../atoms'
import { CommonModule } from '@angular/common'
import { MatListModule } from '@angular/material/list'
import { IQuestion } from 'src/app/types/question.type'

@Component({
  selector: 'app-questions-section',
  standalone: true,
  imports: [QuestionComponent, CommonModule, MatListModule],
  templateUrl: './questions-section.component.html',
})
export class QuestionsSectionComponent {
  @Input() questions: IQuestion[] = []
}
