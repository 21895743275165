import { CommonModule, DatePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSelectModule } from '@angular/material/select'
import { ActivatedRoute, Router, RouterLink } from '@angular/router'
import { zip } from 'rxjs'
import { PageSpinnerComponent } from 'src/app/components/molecules/page-spinner/page-spinner.component'
import { AuthService } from 'src/app/services/auth.service'
import { JeDeclareApiService } from 'src/app/services/je-declare-api.service'
import { ICompany } from 'src/app/types/company.type'
import {
  IReportFormCategory,
  IReportFormHierarchyAwareness,
  IReportFormHow,
} from 'src/app/types/report-form.type'
import { IReportMessage } from 'src/app/types/report-message.type'
import { IReport, ReportStatusMapping } from 'src/app/types/report.type'
import { ReportChatComponent } from '../../components/organisms/report-chat/report-chat.component'
import { ReportDetailsComponent } from '../../components/organisms/report-details/report-details.component'

@Component({
  selector: 'app-report-id',
  standalone: true,
  imports: [
    CommonModule,
    DatePipe,
    FormsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    PageSpinnerComponent,
    ReportChatComponent,
    ReportDetailsComponent,
    RouterLink,
  ],
  templateUrl: './report-id.component.html',
  styleUrl: './report-id.component.scss',
})
export class ReportIdComponent implements OnInit {
  accessToken: string | null

  reportId: string | null = null

  report?: IReport

  reportMessages: IReportMessage[] = []
  reportPassword?: string

  reportCategory?: IReportFormCategory
  reportCompanies?: ICompany[]
  reportHow?: IReportFormHow
  reportHierarchyAwareness?: IReportFormHierarchyAwareness

  companies: ICompany[] = []

  messageInput: string = ''
  ReportStatusMapping = ReportStatusMapping
  statusList = Object.entries(ReportStatusMapping).map(([id, value]) => ({
    id: id,
    name: value,
  }))

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: JeDeclareApiService,
    private authService: AuthService,
  ) {
    this.accessToken = authService.currentAccessToken()
    const state = this.router.getCurrentNavigation()?.extras.state
    this.reportPassword = state?.['reportPassword']

    this.route.params.subscribe((params) => {
      const id = params['id']

      zip(
        this.apiService.getReportById(id, this.reportPassword),
        this.apiService.getReportMessages(id, this.reportPassword),
      ).subscribe({
        next: ([reportResponse, messagesResponse]) => {
          this.report = reportResponse.data
          this.reportMessages =
            messagesResponse.data?.sort((a, b) =>
              a.createdAt > b.createdAt
                ? -1
                : b.createdAt > a.createdAt
                ? 1
                : 0,
            ) ?? []
        },
        error: (err) => {
          console.warn('Error while retrieving report data:', err)
          this.router.navigate(['/'])
        },
      })
    })

    setInterval(() => {
      if (!this.report) return

      this.apiService
        .getReportMessages(this.report.id, this.reportPassword)
        .subscribe({
          next: (response) => {
            this.reportMessages =
              response.data?.sort((a, b) =>
                a.createdAt > b.createdAt
                  ? -1
                  : b.createdAt > a.createdAt
                  ? 1
                  : 0,
              ) ?? []
          },
          error: (err) => {
            this.router.navigate(['/'])
          },
        })
    }, 2 /* MINUTES */ * 60 * 1000)
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.reportId = params.get('id')
    })

    if (!this.reportId) {
      this.router.navigate(['/'])
    } else if (
      document.location.pathname.startsWith('/suivi/signalement') &&
      this.accessToken
    ) {
      this.router.navigate([`/admin/signalement/${this.reportId}`])
    }
  }

  onStatusChange = () => {
    if (!this.report) return

    this.apiService
      .updateReportById(this.report.id, { status: this.report.status })
      .subscribe({
        next: (response) => (this.report = response.data),
        error: (err) => console.error(err),
      })
  }
}
