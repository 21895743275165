<section id="main-form__section" class="full-width">
  <form id="main-form__container">
    <h1 id="main-form__title">Se connecter en tant que gestionnaire</h1>
    <mat-form-field class="full-width">
      <mat-label>E-mail du gestionnaire</mat-label>
      <input name="email" matInput [formControl]="emailFC" type="email" />
      <mat-error *ngIf="!emailIsValid()">L'adresse e-mail est obligatoire</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Mot de passe</mat-label>
      <input name="password" matInput [formControl]="passwordFC" [type]="displayPassword ? 'text' : 'password'" />
      <button matSuffix mat-icon-button aria-label="Clear"
      (click)="togglePasswordVisibility()">
      <mat-icon>{{ displayPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-error *ngIf="!passwordIsValid()">Le mot de passe est obligatoire</mat-error>
    </mat-form-field>

    <div id="spinner-container" *ngIf="isLoading">
      <mat-spinner></mat-spinner>
    </div>

    <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
    <button mat-button mat-raised-button color="primary" *ngIf="!isLoading" type="submit" (click)="!formIsValid() || isLoading ? null : login()" [disabled]="!formIsValid() || isLoading">Se connecter</button>
    <button mat-button color="primary" style="margin-top: 1rem;" (click)="forgotPasswordClick()">Mot de passe oublié ?</button>
  </form>
</section>
