import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { Router } from '@angular/router'
import { JeDeclareApiService } from 'src/app/services/je-declare-api.service'

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  emailFC = new FormControl('', [
    Validators.required,
    Validators.pattern(/^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/),
  ])

  passwordFC = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ])

  confirmPasswordFC = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ])

  resetPasswordForm = this.formBuilder.group(
    {
      newPassword: this.passwordFC,
      confirmPassword: this.confirmPasswordFC,
    },
    {
      validator: this.ConfirmedValidator('newPassword', 'confirmPassword'),
    },
  )

  codeFC = new FormControl('', [
    Validators.required,
    Validators.pattern(/^[0-9][0-9]{5,5}$/),
  ])

  errorMessage: string | undefined = undefined
  isLoading = false

  requestSucceeded = false
  passwordRequestSucceeded = false

  passwordDisplayed = false
  confirmPasswordDisplayed = false

  constructor(
    private router: Router,
    private apiService: JeDeclareApiService,
    private formBuilder: FormBuilder,
  ) {}

  onSubmitClick = () => {
    if (this.emailFC.value) {
      this.isLoading = true
      this.apiService
        .forgotPassword(this.emailFC.value)
        .subscribe({
          next: (response) => {
            // this.router.navigate(['/admin/reset-password'])
            this.errorMessage = undefined
            this.requestSucceeded = true
            this.emailFC.disable()
          },
          error: (err) => {
            console.error(err)
            if (err?.error) {
              this.errorMessage = `Une erreur est survenue: ${err.error.message}`
            }
          },
        })
        .add(() => (this.isLoading = false))
    }
  }

  emailIsValid = () => this.emailFC.valid
  passwordIsValid = () => this.passwordFC.valid
  confirmPasswordIsValid = () => this.confirmPasswordFC.valid
  codeIsValid = () => this.codeFC.valid

  onPasswordSubmitClick = () => {
    const email = this.emailFC.value
    const newPassword = this.passwordFC.value
    const confirmPassword = this.confirmPasswordFC.value
    const code = this.codeFC.value

    if (newPassword === confirmPassword && email && newPassword && code) {
      this.isLoading = true
      this.errorMessage = undefined

      this.apiService
        .resetPassword(email, newPassword, { code })
        .subscribe({
          next: (response) => this.handleFormSucceeded(),
          error: (err) => {
            console.error(err)
            this.errorMessage = err?.error?.message ?? err?.message
          },
        })
        .add(() => (this.isLoading = false))
    }
  }

  resetPasswordFormIsValid = () =>
    this.resetPasswordForm.valid && this.codeIsValid()

  handleFormSucceeded = () => {
    this.resetPasswordForm.reset()
    this.codeFC.reset()
    this.passwordRequestSucceeded = true
  }

  handleRedirect = () => this.router.navigate(['/admin/login'])

  togglePasswordVisibility = (
    target: HTMLInputElement,
    el: 'password' | 'confirmPassword',
  ) => {
    target.type === 'password'
      ? (target.type = 'text')
      : (target.type = 'password')
    el === 'password'
      ? (this.passwordDisplayed = !this.passwordDisplayed)
      : (this.confirmPasswordDisplayed = !this.confirmPasswordDisplayed)
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName]
      const matchingControl = formGroup.controls[matchingControlName]
      if (
        matchingControl.errors &&
        !matchingControl.errors['confirmedValidator']
      ) {
        return
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true })
      } else {
        matchingControl.setErrors(null)
      }
    }
  }
}
