<section id="main-form__section" class="full-width">
  <div id="main-form__container" *ngIf="passwordRequestSucceeded">
    <h1 id="main-form__title">Mot de passe modifié</h1>
    <p>Votre mot de passe a été changé avec succès ! Vous pouvez à présent vous connecter :</p>
    <button mat-button mat-raised-button color="primary" (click)="handleRedirect()">Page de connexion</button>
  </div>

  <form id="main-form__container" *ngIf="!passwordRequestSucceeded">
    <h1 id="main-form__title">Changer le mot de passe</h1>
    <p>Il s'agit de votre première connexion. Veuillez modifier votre mot de passe afin de sécuriser l'accès à votre compte.</p>

    <mat-form-field class="full-width">
      <mat-label>Nouveau mot de passe</mat-label>
      <input name="password" #password matInput [formControl]="passwordFC" type="password" />
      <button matSuffix mat-icon-button aria-label="Clear" (click)="togglePasswordVisibility(password, 'password')">
        <mat-icon>{{ passwordDisplayed ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-error *ngIf="!passwordIsValid()">8 caractères minimum</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Confirmation du mot de passe</mat-label>
      <input name="confirmPassword" #confirmPassword matInput [formControl]="confirmPasswordFC" type="password" />
      <mat-error *ngIf="!confirmPasswordIsValid()">Les mots de passe ne sont pas similaires</mat-error>
      <button matSuffix mat-icon-button aria-label="Clear" (click)="togglePasswordVisibility(confirmPassword, 'confirmPassword')">
        <mat-icon>{{ confirmPasswordDisplayed ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
    </mat-form-field>

    <div id="spinner-container" *ngIf="isLoading">
      <mat-spinner></mat-spinner>
    </div>

    <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
    <button mat-button mat-raised-button color="primary" *ngIf="!isLoading" type="submit" (click)="!formIsValid() || isLoading ? null : onSubmitClick()" [disabled]="!formIsValid() || isLoading">Changer le mot de passe</button>
  </form>
</section>
