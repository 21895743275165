<mat-card id="report-card" *ngIf="report">
    <ng-container *ngIf="isDetailsPage">
        <mat-card-header id="report-card__header">
            <mat-card-title>Détails du signalement : {{ report.id }}</mat-card-title>
            <mat-card-subtitle>
                <div class="status-label" *ngIf="!isEditingStatus || !isLoggedIn()">
                    État : {{ showReadableStatus(report.status) | uppercase }}
                </div>
                @if (isLoggedIn()) {
                <div class="status-label" *ngIf="isEditingStatus">
                    État :
                    <mat-form-field>
                        <mat-label>Nouvel état</mat-label>
                        <mat-select [(value)]="status" required (selectionChange)="onStatusChange()" [disabled]="isSavingStatus">
                            @for (status of statusList; track status) {
                            <mat-option [value]="status.id">{{ status.name }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="status-button-row">
                    <div class="status-flex-container">
                        <div class="status-button-container" *ngIf="!isEditingStatus && !isSavingStatus">
                            <button mat-mini-fab aria-label="Edit status button" [disabled]="isEditingStatus" (click)="onEditStatusClick()">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                        <div class="status-button-container" *ngIf="isEditingStatus && !isSavingStatus">
                            <button mat-mini-fab aria-label="Save status button" [disabled]="!statusHasChanged" (click)="onSubmitStatus()">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>
                        <div class="status-button-container" *ngIf="isEditingStatus && !isSavingStatus">
                            <button mat-mini-fab aria-label="Cancel status button" [disabled]="!(isEditingStatus && !isSavingStatus)" (click)="initStatus()">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </div>
                        <div class="status-button-container">
                            <mat-spinner id="status-spinner" *ngIf="isSavingStatus"></mat-spinner>
                        </div>
                    </div>
                </div>
                }
            </mat-card-subtitle>
            <button mat-fab extended aria-label="Download button" id="download-btn" (click)="exportToPDF()">
                <mat-icon>download</mat-icon> Télécharger
            </button>
        </mat-card-header>
        <mat-divider></mat-divider>
    </ng-container>

    <mat-card-content id="report-card__content">
        <h3>Catégorie</h3>
        <p>{{ reportCategory?.text || "NC" }}</p>

        <h3>Informations personnelles</h3>
        <p *ngIf="!report.user"><b>Je souhaite rester anonyme pour ce signalement : </b><br />Oui</p>

        <div *ngIf="report.user" id="report-card__splitted">
            <div class="report-card__splitted-half">
                <p><b>Prénom :</b><br />{{ report.user.firstName }}</p>
                <p><b>Numéro de téléphone :</b><br />{{ report.user.phoneNumber }}</p>
                <p><b>Organisation / Entreprise :</b><br />{{ report.user.company }}</p>
            </div>
            <div class="report-card__splitted-half">
                <p><b>Nom :</b><br />{{ report.user.lastName }}</p>
                <p><b>Email :</b><br />{{ report.user.email }}</p>
                <p><b>Fonction :</b><br />{{ report.user.job }}</p>
            </div>
        </div>

        <mat-divider></mat-divider>

        <h3>Contenu du signalement</h3>

        <p><b>Titre du signalement</b><br />{{ report.title || "NC" }}</p>

        <p><b>Société(s) concernée(s) ?</b><br />{{ !reportCompanies ? "NC" : ""}}</p>
        <ul>
            <li *ngFor="let company of selectedCompanies ?? reportCompanies || []">{{ company?.name }}</li>
            <li *ngFor="let company of otherCompanies ?? reportOtherCompanies || []">{{ company?.name }}</li>
        </ul>

        <p><b>Comment avez-vous eu connaissance des faits relatifs à l’infraction ?</b>
            <br />{{ reportHow?.text || "NC" }}
        </p>

        <p><b>Détails complémentaires</b><br />{{ report.details || "NC" }}</p>

        <p><b>Date des faits</b><br />{{ report.when || "NC" }}</p>

        <p><b>Lieu des faits</b><br />{{ report.where || "NC" }}</p>

        <p><b>Personne(s) impliquée(s)</b><br />{{ report.involvedPersons || "NC" }}</p>

        <p><b>Votre hiérarchie a-t-elle connaissance de ces faits ?</b><br />{{ reportHierarchyAwareness?.text || "NC"
            }}
        </p>

        <ng-container *ngIf="report.files?.length && isDetailsPage">
            <mat-divider></mat-divider>
            <h3 class="report-attachments__title">Pièces-jointes</h3>

            <ul id="report-attachments__list">
                <li *ngFor="let file of report.files">
                    <a [href]="file.url" target="_blank">{{ file.originalName }} ({{ showRoundedFileSize(file.size)
                        }})</a>
                </li>
            </ul>
        </ng-container>
    </mat-card-content>

    <mat-card-footer></mat-card-footer>
</mat-card>