<h2 mat-dialog-title>Effacement prévu dans {{ period }}</h2>
<mat-dialog-content>
  Le signalement sera marqué comme {{ isRejected ? "rejeté" : "traité" }}. Il sera automatiquement effacé dans {{ period }}.
  <br/>Vous pouvez le rouvrir à tout moment durant cette période.
  <br /><br /><b>Une fois la période de {{ period }} écoulée, le signalement ne sera plus accessible.</b>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Annuler</button>
  <button mat-flat-button [mat-dialog-close]="true" class="btn-secondary">
    Mettre à jour
  </button>
</mat-dialog-actions>