import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ILoginResponse } from '../types/authentication.type'
import { ICompany } from '../types/company.type'
import {
  DeleteFailedFiles,
  IReportForm,
  IReportFormCompany,
} from '../types/report-form.type'
import {
  CreateReportMessageRequestBody,
  IReportMessage,
} from '../types/report-message.type'
import {
  CreateReportSuccess,
  IReport,
  RemoveFailedFilesResponse,
  ReportFilters,
} from '../types/report.type'

interface Response<T> {
  data?: T
  lastEvaluatedKey?: string
  error?: Error
}

@Injectable({
  providedIn: 'root',
})
export class JeDeclareApiService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) {}

  // COMPANIES
  getCompanies(): Observable<Response<ICompany[]>> {
    return this.http.get<Response<ICompany[]>>(`${this.apiUrl}/companies`)
  }

  createCompany(company: IReportFormCompany): Observable<Response<ICompany>> {
    return this.http.post<Response<ICompany>>(
      `${this.apiUrl}/companies`,
      company,
    )
  }

  deleteCompany() {
    return this.http.get(`${this.apiUrl}/companies`).subscribe()
  }

  // REPORTS
  getReports(params?: ReportFilters) {
    let httpParams = new HttpParams()

    if (params) {
      Object.keys(params).forEach((key) => {
        let value: string | number | string[] =
          params[key as keyof ReportFilters]!

        if (Array.isArray(value)) value = value.join(',')

        httpParams = httpParams.append(key, `${value}`)
      })
    }

    return this.http.get<Response<IReport[]>>(`${this.apiUrl}/reports`, {
      params: httpParams,
    })
  }

  createReport(report: IReportForm): Observable<Response<CreateReportSuccess>> {
    return this.http.post<Response<CreateReportSuccess>>(
      `${this.apiUrl}/reports`,
      report,
    )
  }

  deleteFailedFiles(
    password: string,
    body: DeleteFailedFiles,
  ): Observable<Response<RemoveFailedFilesResponse>> {
    return this.http.post<Response<RemoveFailedFilesResponse>>(
      `${this.apiUrl}/remove-failed-files`,
      body,
      { headers: { Authorization: password } },
    )
  }

  getReportById(id: string, password?: string) {
    return this.http.get<Response<IReport>>(`${this.apiUrl}/reports/${id}`, {
      headers: {
        ...(password && {
          Authorization: `Bearer ${password}`,
        }),
      },
    })
  }

  updateReportById(id: string, report: Partial<IReport>) {
    return this.http.patch<Response<IReport>>(
      `${this.apiUrl}/reports/${id}`,
      report,
    )
  }

  getReportMessages(reportId: string, reportPassword?: string) {
    return this.http.get<Response<IReportMessage[]>>(
      `${this.apiUrl}/reports/${reportId}/messages`,
      {
        headers: {
          ...(reportPassword && {
            Authorization: `Bearer ${reportPassword}`,
          }),
        },
      },
    )
  }

  createReportMessage(
    reportId: string,
    message: CreateReportMessageRequestBody,
    reportPassword?: string,
  ) {
    return this.http.post<Response<IReportMessage>>(
      `${this.apiUrl}/reports/${reportId}/messages`,
      message,
      {
        headers: {
          ...(reportPassword && {
            Authorization: `Bearer ${reportPassword}`,
          }),
        },
      },
    )
  }

  sendReportCredentialsByEmail(email: string, id: string, password: string) {
    return this.http.post(`${this.apiUrl}/email/report-credentials/send`, {
      email: email,
      id: id,
      password: password,
    })
  }

  // ADMIN
  login(email: string, password: string) {
    return this.http.post<ILoginResponse>(`${this.apiUrl}/admin/login`, {
      email: email,
      password: password,
    })
  }

  forgotPassword(email: string) {
    // Don't know the response body because it don't work (JD-110)
    return this.http.post(`${this.apiUrl}/admin/forgot-password`, {
      email: email,
    })
  }

  resetPassword(
    email: string,
    newPassword: string,
    options: ResetPasswordOptions,
  ) {
    const { session, code } = options ?? {}
    return this.http.put<ILoginResponse>(
      `${this.apiUrl}/admin/reset-password`,
      {
        email: email,
        newPassword: newPassword,
        ...(session && {
          session: session,
        }),
        ...(code && {
          code,
        }),
      },
    )
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.http.put(`${this.apiUrl}/admin/password`, {
      oldPassword: oldPassword,
      newPassword: newPassword,
    })
  }
}

interface ResetPasswordOptions {
  session?: string
  code?: string
}
