import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ILoginResponse } from '../types/authentication.type'
import { SessionStorageUtils } from '../utils/session-storage.utils'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private router: Router) {}

  login(loginRes: ILoginResponse) {
    SessionStorageUtils.ACCESS_TOKEN.set(loginRes.AccessToken)
    this.router.navigate(['admin'])
  }

  logout() {
    SessionStorageUtils.ACCESS_TOKEN.remove()
    this.router.navigate(['/'])
  }

  currentAccessToken = () => SessionStorageUtils.ACCESS_TOKEN.get()

  isAuthenticated(): boolean {
    return !!SessionStorageUtils.ACCESS_TOKEN.get()
  }
}
