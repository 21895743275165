<!-- <div class="container">
  <div class="header-signalement">
    <ng-container *ngFor="let step of steps, let isFirst = first, let isLast = last">
  
      <a [routerLink]="step.link" *ngIf="step.clickable();else item">
        <div *ngTemplateOutlet="item"></div>
      </a>
  
      <ng-template #item>
        <div>
          <div [ngClass]="{'header-signalement-btn': true, 'active': step.active(), 'clickable': step.clickable()}">
            <div class="header-signalement-number">{{ step.number }}</div>
            <div class="header-signalement-text">{{ step.title }}</div>
          </div>
        </div>
      </ng-template>
  
    </ng-container>
  </div>
  
  <router-outlet></router-outlet>
</div> -->

<app-report-stepper></app-report-stepper>
