import { CommonModule } from '@angular/common'
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatChipsModule } from '@angular/material/chips'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { ToastrService } from 'ngx-toastr'
import { UploadedFile } from 'src/app/types/files.type'
import { v4 as uuid } from 'uuid'

@Component({
  selector: 'app-files-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatChipsModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
  ],
  templateUrl: './files-selector.component.html',
  styleUrl: './files-selector.component.scss',
})
export class FilesSelectorComponent implements OnDestroy {
  @Input() isLoading? = false
  @Output() filesChange: EventEmitter<UploadedFile[]> = new EventEmitter()

  files: UploadedFile[] = []

  constructor(private toastr: ToastrService) {}

  ngOnDestroy(): void {
    this.toastr.clear()
  }

  onFilesSelected = (event: any, input: HTMLInputElement) => {
    // All files from HTML input
    const inputFiles = (Array.from(event?.target?.files ?? []) as File[]).map(
      (file) => ({
        originalName: file.name,
        file: new File([file], `${uuid()}_${file.name}`, {
          type: file.type,
        }),
      }),
    )

    // 5 MB in bytes
    const fiveMB = 5 * 1024 * 1000

    // Get only files with right type and size < 5 MB
    this.files = sortArrAsc([...this.files, ...inputFiles]).filter(
      (f) => validFileType.includes(f.file.type) && f.file.size <= fiveMB,
    )

    inputFiles
      .filter((f) => f.file.size > fiveMB)
      .forEach((f) => {
        this.toastr.error(
          `Le fichier "${f.file.name}" n'a pas été ajouté car sa taille dépasse 5 Mo.`,
          'Fichier trop large',
          { disableTimeOut: true },
        )
      })

    inputFiles
      .filter((f) => !validFileType.includes(f.file.type))
      .forEach((f) => {
        this.toastr.error(
          `Le fichier "${f.file.name}" n'a pas été ajouté car son type n'est pas autorisé.`,
          'Format non autorisé',
          { disableTimeOut: true },
        )
      })

    this.filesChange.emit(this.files)
    input.value = ''
  }

  removeFile = (index: number) => {
    this.files.splice(index, 1)
    this.filesChange.emit(this.files)
  }

  clearAllFiles = () => (this.files = [])

  inlineValidFileType = () => validFileType.join(',')

  showRoundedFileSize = (size: number) =>
    `${size}`.length < 6
      ? `${(size / 1024).toFixed(0)} ko`
      : `${(size / 1024 ** 2).toFixed(2)} Mo`
}

const validFileType = [
  'image/png',
  'image/jpeg',
  'image/avif',
  'image/svg+xml',
  'text/csv',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.graphics',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-excel',
  'application/msword',
  'application/pdf',
]

const sortArrAsc = (arr: UploadedFile[]) =>
  arr.sort((a, b) =>
    a.originalName.toLowerCase() > b.originalName.toLowerCase() ? 1 : -1,
  )
