import { registerLocaleData } from '@angular/common'
import localeFr from '@angular/common/locales/fr'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import {
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http'
import { MatIconModule } from '@angular/material/icon'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { ToastrModule } from 'ngx-toastr'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { LayoutComponent } from './components/templates/layout/layout.component'
import { ReportFormComponent } from './pages/report-form/report-form.component'
import { authInterceptor } from './services/auth.interceptor'

registerLocaleData(localeFr)

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    LayoutComponent,
    MatIconModule,
    ReportFormComponent,
    ToastrModule.forRoot(),
  ],
  providers: [
    provideHttpClient(withInterceptors([authInterceptor])),
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
