import { CommonModule } from '@angular/common'
import {
  AfterContentInit,
  Component,
  inject,
  Input,
  OnChanges,
} from '@angular/core'
import { MatCardModule } from '@angular/material/card'
import { MatDividerModule } from '@angular/material/divider'
import {
  reportCategories,
  reportHierarchyAwareness,
  reportHow,
} from 'src/app/data/report.data'
import { JeDeclareApiService } from 'src/app/services/je-declare-api.service'
import { ICompany } from 'src/app/types/company.type'
import {
  IReportFormCategory,
  IReportFormCompany,
  IReportFormHierarchyAwareness,
  IReportFormHow,
} from 'src/app/types/report-form.type'
import {
  EReportStatus,
  IReport,
  ReportStatusMapping,
} from 'src/app/types/report.type'

import { FormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
} from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSelectModule } from '@angular/material/select'
import html2pdf from 'html2pdf.js'
import { AuthService } from 'src/app/services/auth.service'

@Component({
  selector: 'app-report-details',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSelectModule,
  ],
  templateUrl: './report-details.component.html',
  styleUrl: './report-details.component.scss',
})
export class ReportDetailsComponent implements OnChanges, AfterContentInit {
  @Input() report?: IReport | Record<string, any>
  @Input() isDetailsPage = false
  @Input() selectedCompanies?: IReportFormCompany[]
  @Input() otherCompanies?: IReportFormCompany[]

  reportCategory?: IReportFormCategory
  reportCompanies?: ICompany[]
  reportOtherCompanies?: ICompany[]
  reportHow?: IReportFormHow
  reportHierarchyAwareness?: IReportFormHierarchyAwareness

  companies: ICompany[] = []

  statusList = Object.entries(ReportStatusMapping).map(([id, value]) => ({
    id: id,
    name: value,
  }))

  isEditingStatus: boolean
  isSavingStatus: boolean
  statusHasChanged: boolean
  status = EReportStatus.OnHold

  dialog = inject(MatDialog)

  constructor(
    private apiService: JeDeclareApiService,
    private authService: AuthService,
  ) {
    this.isEditingStatus = false
    this.isSavingStatus = false
    this.statusHasChanged = false

    this.apiService.getCompanies().subscribe((response) => {
      this.companies = response.data ?? []
      this.reportCompanies = this.report?.companies?.map(
        (id: string) => this.companies.find((company) => company.id == id)!,
      )
      this.reportOtherCompanies = this.report?.otherCompanies
    })
  }

  ngAfterContentInit() {
    this.status = this.report?.status
  }

  ngOnChanges() {
    this.selectedCompanies = this.selectedCompanies?.filter(
      (c) => c.id !== 'other',
    )
    this.reportCategory = reportCategories.find(
      (category) => category.id == this.report?.category,
    )!
    this.reportCompanies = this.report?.companies?.map(
      (id: string) =>
        this.companies.find((company) => company.id == id) ?? { id, name: id },
    )
    this.reportHow = reportHow.find((how) => how.id == this.report?.how)
    this.reportHierarchyAwareness = reportHierarchyAwareness.find(
      (hierarchyAwareness) =>
        hierarchyAwareness.id == this.report?.hierarchyAwareness,
    )
  }

  isLoggedIn = () => this.authService.isAuthenticated()

  showReadableStatus = (state?: string) =>
    this.statusList.filter((s) => s.id === state)[0]?.name

  showRoundedFileSize = (size: number) =>
    `${size}`.length < 6
      ? `${(size / 1024).toFixed(0)} ko`
      : `${(size / 1024 ** 2).toFixed(2)} Mo`

  onEditStatusClick = () => {
    this.isEditingStatus = true
  }

  initStatus = () => {
    this.status = this.report?.status
    this.isEditingStatus = false
    this.statusHasChanged = false
  }

  onStatusChange = () =>
    (this.statusHasChanged = !(this.status === this.report?.status))

  updateStatusRequest = () => {
    this.isSavingStatus = true
    this.apiService
      .updateReportById(this.report?.id, { status: this.status })
      .subscribe({
        next: (response) => {
          this.report = response.data as IReport
          this.initStatus()
        },
        error: (error) =>
          console.warn(
            "An error has occurred when updating report's status:",
            error,
          ),
      })
      .add(() => (this.isSavingStatus = false))
  }

  openDialog = () => {
    const dialogRef = this.dialog.open(DialogDataExampleDialog, {
      data: { status: this.status },
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.updateStatusRequest()
    })
  }

  onSubmitStatus = () => {
    if ([EReportStatus.Rejected, EReportStatus.Completed].includes(this.status))
      this.openDialog()
    else this.updateStatusRequest()
  }

  exportToPDF() {
    const element = document.getElementById('report-card')
    const downloadIcon = document.querySelector(
      '.download-icon',
    ) as HTMLElement | null
    const attachmentsTitle = document.querySelector(
      '.report-attachments__title',
    ) as HTMLElement | null

    if (!element) return

    let originalDisplay: string | null = null
    if (downloadIcon) {
      originalDisplay = downloadIcon.style.display
      downloadIcon.style.display = 'none'
    }

    let originalAttachmentsDisplay: string | null = null
    if (attachmentsTitle) {
      originalAttachmentsDisplay = attachmentsTitle.style.paddingTop
      attachmentsTitle.style.paddingTop = '3rem'
    }
    const opt = {
      filename: `Signalement_${this.report?.id}.pdf`,
      html2canvas: {
        scale: 2,
      },
      jsPDF: {
        unit: 'mm',
        format: 'a4',
        orientation: 'portrait',
      },
      enableLinks: true,
    }

    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => {
        if (downloadIcon && originalDisplay !== null)
          downloadIcon.style.display = originalDisplay
        if (attachmentsTitle && originalAttachmentsDisplay !== null)
          attachmentsTitle.style.paddingTop = originalAttachmentsDisplay
      })
  }
}

@Component({
  selector: 'dialog-data-example-dialog',
  templateUrl: 'deletion-modal/deletion-modal.component.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
})
export class DialogDataExampleDialog {
  data = inject(MAT_DIALOG_DATA)

  period: string
  isRejected: boolean

  constructor() {
    this.isRejected = this.data.status === EReportStatus.Rejected
    this.period = this.isRejected ? '48h' : '3 mois'
  }
}
