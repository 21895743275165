<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title [style.font-weight]="'bold'">
        {{ item.question }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>{{ item.answer }}</p>
  </mat-expansion-panel>
</mat-accordion>
