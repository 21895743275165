import { Component, Input } from '@angular/core'
import { IQuestion } from 'src/app/types/question.type'
import { QuestionsSectionComponent } from '../../molecules'

@Component({
  selector: 'app-questions-orga',
  standalone: true,
  imports: [QuestionsSectionComponent],
  templateUrl: './questions-orga.component.html',
  styleUrl: './questions-orga.component.css',
})
export class QuestionsOrgaComponent {
  @Input() questions: IQuestion[] = []
}
