<input type="file"
       multiple
       class="file-input"
       [accept]="inlineValidFileType()"
       (change)="onFilesSelected($event, fileUpload)"
       #fileUpload
       [disabled]="isLoading"
>

<div class="file-upload">
  <p><b>Ajouter des pièces jointes</b></p>
  <p>La majorité des fichiers de type image, document, présentation, tableur, texte et CSV est accepté.<br/>
  Taille maximale : <b>5 Mo</b>.</p>

  <button mat-button mat-raised-button color="primary" [disabled]="isLoading" (click)="isLoading ? null : fileUpload.click()">
    <mat-icon>attach_file</mat-icon> Sélectionner des fichiers
  </button>
  &nbsp;
  <button mat-button [disabled]="!files.length || isLoading" color="primary" (click)="isLoading ? null : clearAllFiles()">
    <mat-icon>clear_all</mat-icon> Effacer tous les fichiers
  </button>

  <p *ngIf="!files.length"><em>Aucun fichier sélectionné</em></p>
  
  <mat-chip-set *ngIf="files.length">
    <mat-chip *ngFor="let file of files; index as i" (removed)="removeFile(i)">
      {{ file.originalName }} ({{ showRoundedFileSize(file.file.size) }})
      <button *ngIf="!isLoading" matChipRemove aria-label="'remove ' + file.originalName"><mat-icon>cancel</mat-icon></button>
    </mat-chip>
  </mat-chip-set>
</div>