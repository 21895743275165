import { CommonModule, DatePipe } from '@angular/common'
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { ActivatedRoute, Router, RouterLink } from '@angular/router'
import { jwtDecode } from 'jwt-decode'
import { Subscription, interval } from 'rxjs'
import { AuthService } from 'src/app/services/auth.service'
import { JeDeclareApiService } from 'src/app/services/je-declare-api.service'
import { IReportMessage } from 'src/app/types/report-message.type'

@Component({
  selector: 'app-report-chat',
  standalone: true,
  imports: [
    CommonModule,
    DatePipe,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    RouterLink,
    MatProgressSpinnerModule,
    MatButtonModule,
  ],
  templateUrl: './report-chat.component.html',
  styleUrls: ['./report-chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportChatComponent implements OnChanges {
  @Input() reportId: string = ''
  @Input() reportPassword: string = ''

  accessToken: string | null

  reportMessages: IReportMessage[] = []
  messageInput: string = ''

  isSending = false
  chatIsAvailable = false
  errorMessage = ''

  timerSubscription: Subscription | undefined
  countdown: number = 120 // 2 minutes countdown
  isLoadingMessages = false

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: JeDeclareApiService,
    private authService: AuthService,
  ) {
    this.accessToken = authService.currentAccessToken()
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { reportId, reportPassword } = changes

    if (reportId) this.reportId = reportId.currentValue
    if (reportPassword) this.reportPassword = reportPassword.currentValue

    if ((this.reportId && this.accessToken) || this.reportPassword) {
      this.loadMessages()
      this.startTimer()
    }
  }

  loadMessages() {
    this.isLoadingMessages = true
    this.apiService
      .getReportMessages(this.reportId, this.reportPassword)
      .subscribe({
        next: (response) => {
          this.reportMessages =
            response.data?.sort((a, b) =>
              a.createdAt > b.createdAt
                ? -1
                : b.createdAt > a.createdAt
                ? 1
                : 0,
            ) ?? []
          this.chatIsAvailable = true
          this.isLoadingMessages = false
          this.startTimer() // Restart the timer after messages are loaded
        },
        error: (err) => {
          this.errorMessage = `Service indisponible : ${
            err?.error?.message ?? err?.message
          }`
          this.isLoadingMessages = false
        },
      })
  }

  startTimer() {
    this.timerSubscription?.unsubscribe()
    this.countdown = 120
    this.timerSubscription = interval(1000).subscribe(() => {
      this.countdown--
      if (this.countdown <= 0) {
        this.loadMessages()
      }
    })
  }

  isSender = (message: IReportMessage): boolean => {
    if (this.reportPassword) {
      return message.senderId == this.reportId
    }
    if (this.accessToken) {
      return message.senderId != this.reportId
    }
    return false
  }

  activateSendIcon = () =>
    this.messageInput.trim() !== '' && !this.isSending && this.chatIsAvailable

  humanReadableCountdown = () =>
    this.countdown < 60
      ? `${this.countdown}s`
      : this.countdown % 60
      ? `${Math.floor(this.countdown / 60)}m ${this.countdown % 60}s`
      : `${this.countdown / 60}m`

  submitMessage = () => {
    this.messageInput = this.messageInput.trim()
    const value = this.messageInput

    if (value !== '') {
      this.isSending = true
      this.apiService
        .createReportMessage(
          this.reportId,
          {
            reportId: this.reportId,
            senderId: this.accessToken
              ? (jwtDecode(this.accessToken) as any).username
              : this.reportId,
            body: value,
          },
          this.reportPassword,
        )
        .subscribe({
          next: (response) => {
            const message = response.data
            if (message) this.reportMessages.unshift(message)
            this.messageInput = ''
            this.isSending = false
          },
          error: (err) => {
            this.isSending = false
            console.warn('Error while sending the message:', err)
            this.router.navigate(['/'])
          },
        })
    }
  }
}
