<header class="home-header text-center">
    <img src="assets/images/42c-logo.png" alt="42c logo" class="logo" />
    <h1>{{ texts.welcomeTitle }}</h1>
</header>

<app-banner [title]="'Je déclare'" [content]="texts.welcomeContent"
    [imageUrl]="'assets/images/banniere.png'"></app-banner>

<div class="information-section">
    <div class="row">
        <div class="section-title">
            <mat-icon svgIcon="profil_icon" class="custom-color" />
            <h2 class="uppercase">{{ texts.orgProfileTitle }}</h2>
        </div>
        <p>
            <b>Organisation </b>
            <span>{{ texts.orgDetails.organizationNames }}</span>
        </p>
        <div class="list-div">
            <div>
                <b>Référent(s) </b>
                <ul>
                    <li>{{ texts.orgDetails.referents }}</li>
                </ul>
            </div>
            <div>
                <b>Document(s)</b>
                <ul>
                    <li>
                        <a (click)="downloadDocument('politique-de-confidentialite_v0.pdf')">{{
                            texts.orgDetails.documents[0].name }}</a>
                    </li>
                </ul>
            </div>

        </div>
    </div>

    <div class="row">
        <div class="section-title">
            <mat-icon svgIcon="important_icon" class="custom-color" />
            <h2 class="uppercase">À noter</h2>
        </div>
        <p class="important-content" [innerHTML]="texts.importantContent"></p>
    </div>
</div>
<app-action-card></app-action-card>

<app-questions-orga [questions]="questions"></app-questions-orga>