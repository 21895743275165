import { IReportForm } from "../types/report-form.type";
import { isJson } from "./utils";

class SessionStorage<T> {

  private key: string

  constructor(key: string) {
    this.key = key
  }

  set = (object: T): void => {
    if (typeof object === 'object') {
      sessionStorage.setItem(this.key, JSON.stringify(object))
    } else if (typeof object === 'string') {
      sessionStorage.setItem(this.key, object)
    } else {
      sessionStorage.setItem(this.key, object as any)
    }
  };
  get = (): T | null => {
    const value = sessionStorage.getItem(this.key)
    if (value == null) {
      return value
    } else if (isJson(value)) {
      return JSON.parse(value)
    } else {
      return value as T
    }
  };
  remove = (): void => {
    sessionStorage.removeItem(this.key)
  };
}

export const SessionStorageUtils = {
  clear: () => sessionStorage.clear(),

  ACCESS_TOKEN: new SessionStorage<string>("accessToken"),
  REPORT: new SessionStorage<IReportForm>("report"),
}