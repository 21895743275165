import { CommonModule, DatePipe } from '@angular/common'
import { AfterViewInit, Component, ViewChild } from '@angular/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSort, MatSortModule } from '@angular/material/sort'
import { MatTableDataSource, MatTableModule } from '@angular/material/table'
import { Router } from '@angular/router'
import { JeDeclareApiService } from 'src/app/services/je-declare-api.service'
import { ICompany } from 'src/app/types/company.type'
import { IReport, ReportStatusMapping } from 'src/app/types/report.type'

@Component({
  selector: 'app-reports-list',
  standalone: true,
  imports: [
    CommonModule,
    DatePipe,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
  ],
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss'],
})
export class ReportsListComponent implements AfterViewInit {
  private reportsAll: IReport[] = []

  reportsCount = 0
  displayedColumns: (keyof IReport)[] = [
    'id',
    'title',
    'createdAt',
    'status',
    'companies',
  ]
  dataSource: MatTableDataSource<IReport> = new MatTableDataSource<IReport>([])
  displayCompanies: { [key: string]: string } = {}

  isLoading = true
  ReportStatusMapping = ReportStatusMapping

  exclusiveStartKey: string | undefined = undefined

  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort

  constructor(
    private router: Router,
    private apiService: JeDeclareApiService,
  ) {}

  ngAfterViewInit() {
    this.apiService.getCompanies().subscribe((response) => {
      const allCompanies = response.data ?? []
      this.apiService.getReports().subscribe({
        next: (response) => {
          this.reportsAll = (response.data ?? [])
            .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
            .map((report) => ({
              ...report,
              companies: report.companies
                .map((id) => allCompanies.find((a) => a.id === id)?.name)
                .filter((name) => name !== undefined) as string[],
              otherCompanies: report.otherCompanies?.map(
                (o) => (o as ICompany).name,
              ),
            }))

          this.reportsAll.forEach((report) => {
            this.displayCompanies[report.id] = this.showAllCompanies(
              report.companies,
              report.otherCompanies as string[],
            )
          })
          this.dataSource.data = [...this.reportsAll]
          this.dataSource.paginator = this.paginator
          this.dataSource.sort = this.sort
          this.reportsCount = this.reportsAll.length
          this.exclusiveStartKey = response.lastEvaluatedKey
          this.paginator.page.emit()
        },
        error: (err) => {
          console.error(err)
        },
      })
    })
  }

  onReportClick(report: IReport) {
    this.router.navigate([`/admin/signalement/${report.id}`])
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }

  private showAllCompanies = (companies: string[], otherCompanies: string[]) =>
    companies.concat(otherCompanies).join(', ')
}
