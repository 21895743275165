import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { Router, RouterLink } from '@angular/router'
import { ButtonComponent } from 'src/app/components/atoms'
import { AuthService } from 'src/app/services/auth.service'
import { JeDeclareApiService } from 'src/app/services/je-declare-api.service'

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    RouterLink,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  emailFC = new FormControl('', [
    Validators.required,
    Validators.pattern(/^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/),
  ])

  passwordFC = new FormControl('', [Validators.required])

  errorMessage: string | undefined = undefined
  isLoading = false
  displayPassword = false

  constructor(
    private router: Router,
    private apiService: JeDeclareApiService,
    private authService: AuthService,
  ) {
    if (authService.isAuthenticated()) this.router.navigate(['admin'])
  }

  login = () => {
    this.isLoading = true
    this.errorMessage = undefined

    if (this.emailFC.value && this.passwordFC.value)
      this.apiService
        .login(this.emailFC.value, this.passwordFC.value)
        .subscribe({
          next: (value) => {
            if (value.challengeName)
              this.router.navigate(['/admin/reset-password'], {
                queryParams: { session: value.session },
                state: { email: this.emailFC.value },
              })
            else this.authService.login(value)
          },
          error: (err) => {
            this.errorMessage =
              'Veuillez vérifier votre identifiant et votre mot de passe'
            console.error(err)
          },
        })
        .add(() => (this.isLoading = false))
  }

  togglePasswordVisibility = () =>
    (this.displayPassword = !this.displayPassword)

  emailIsValid = () => this.emailFC.valid
  passwordIsValid = () => this.passwordFC.valid
  formIsValid = () => this.emailIsValid() && this.passwordIsValid()

  forgotPasswordClick = () => this.router.navigate(['/admin/forgot-password'])
}
