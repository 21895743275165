import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-banner',
  standalone: true,
  imports: [],
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.css',
})
export class BannerComponent {
  @Input() title: string = ''
  @Input() content: string = ''
  @Input() imageUrl: string = ''
  @Input() imageAlt: string = ''
}
