import { ICompany } from './company.type'

export interface IReport {
  id: string
  category: string
  companies: string[]
  createdAt: string
  details: string
  files?: ReportFile[]
  hierarchyAwareness: string
  how: string
  involvedPersons: string
  otherCompanies?: string[] | ICompany[]
  password: string
  status: EReportStatus
  title: string
  updatedAt: string
  user: IReportUser | null
  when: string
  where: string
}

export interface ReportFile {
  key: string
  originalName: string
  size: number
  url: string
}

export interface CreateReportSuccess {
  report: IReport
  uploads?: {
    presignedUrls?: Record<string, string>
    errors?: { name: string; error: string }[]
  }
}

export interface RemoveFailedFilesResponse {
  message: string
  errors?: { error: any; key: string }[]
}

export interface IReportUser {
  company: string
  email: string
  firstName: string
  job: string
  lastName: string
  phoneNumber: string
}

export enum EReportStatus {
  OnHold = 'on_hold',
  InProgress = 'in_progress',
  Completed = 'completed',
  Rejected = 'rejected',
}

export const ReportStatusMapping: Record<EReportStatus | string, string> = {
  [EReportStatus.OnHold]: 'À traiter',
  [EReportStatus.InProgress]: 'Ouvert',
  [EReportStatus.Completed]: 'Clos',
  [EReportStatus.Rejected]: 'Rejeté',
}

export interface ReportFilters {
  id?: string
  title?: string
  sort?: string[]
  limit?: number
  offset?: number
}
