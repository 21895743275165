<div class="chat-container">
  <div class="timer-container">
    <span>Actualisation des messages dans : {{ humanReadableCountdown() }}</span>
    <button mat-raised-button (click)="loadMessages()">Recharger</button>
  </div>
  <div class="chat-body">
    <mat-progress-spinner
      *ngIf="isLoadingMessages"
      mode="indeterminate"
    ></mat-progress-spinner>
    <div
      class="message"
      [ngClass]="{ me: isSender(message) }"
      *ngFor="let message of reportMessages"
    >
      <div class="message-content">
        {{ message.body }}
        <div class="time">
          {{ message.createdAt | date : 'dd/MM/yyyy à HH:mm' }}
        </div>
      </div>
    </div>
  </div>
  <form class="chat-footer">
    <mat-form-field class="full-width">
      <mat-label>{{
        !chatIsAvailable
          ? errorMessage || 'Une erreur est survenue.'
          : 'Nouveau message'
      }}</mat-label>
      <input
        name="message"
        type="text"
        matInput
        [placeholder]="
          !chatIsAvailable ? 'Une erreur est survenue.' : 'Ex: \'Bonjour\''
        "
        [(ngModel)]="messageInput"
        (keyup.enter)="submitMessage()"
        [disabled]="isSending || !chatIsAvailable"
      />
      <mat-icon
        matSuffix
        id="send-button"
        (click)="!activateSendIcon() ? null : submitMessage()"
        [ngClass]="{ 'send-button__clickable': activateSendIcon() }"
        >send</mat-icon
      >
    </mat-form-field>
  </form>
</div>
