<div class="card-container">
  <mat-card class="mat-card">
    <div>
      <img mat-card-image src="assets/images/new-report.png" alt="new report" />
    </div>

    <mat-divider></mat-divider>
    <mat-card-actions class="actions-container">
      <app-button
        [label]="'Nouveau signalement'"
        [routerLink]="'/signalement'"
        [type]="'button'"
        [color]="'accent'"
        [class]="'action-card-button'"
      ></app-button>
    </mat-card-actions>
  </mat-card>
  <mat-card class="mat-card">
    <img
      mat-card-image
      src="assets/images/resume-report.png"
      alt="resume report"
    />
    <mat-divider></mat-divider>

    <mat-card-actions class="actions-container">
      <app-button
        [label]="'Suivre un signalement'"
        [routerLink]="'/suivi/signalement'"
        [type]="'button'"
        [color]="'accent'"
        [class]="'action-card-button'"
      ></app-button>
    </mat-card-actions>
  </mat-card>
  <mat-card class="mat-card">
    <img mat-card-image src="assets/images/login.png" alt="login" />
    <mat-divider></mat-divider>

    <mat-card-actions class="actions-container">
      <app-button
        [routerLink]="'/admin/login'"
        [label]="'Accès pro'"
        [type]="'button'"
        [color]="'accent'"
        [class]="'action-card-button'"
      ></app-button>
    </mat-card-actions>
  </mat-card>
</div>
