import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { Router, RouterModule } from '@angular/router'

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, CommonModule, RouterModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() label?: string
  @Input() isRaised?: boolean = true
  @Input() disabled?: boolean = false
  @Input() type: 'button' | 'submit' | 'reset' = 'button'
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary'
  @Input() icon?: string
  @Input() logo?: string
  @Input() isIconButton: boolean = false
  @Input() routerLink: string = ''
  @Input() class: string = ''

  @Output() onClick = new EventEmitter<Event>()

  constructor(private router: Router) {}

  handleClick(event: Event): void {
    this.onClick.emit(event)
  }
}
