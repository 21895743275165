import { Component } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatGridListModule } from '@angular/material/grid-list'
import { ButtonComponent } from '../../atoms'
import { MatDividerModule } from '@angular/material/divider'

@Component({
  selector: 'app-action-card',
  standalone: true,
  imports: [
    MatButtonModule,
    MatCardModule,
    MatGridListModule,
    ButtonComponent,
    MatDividerModule,
  ],
  templateUrl: './action-card.component.html',
  styleUrl: './action-card.component.scss',
})
export class ActionCardComponent {}
